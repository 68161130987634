<template>
  <b-modal
    id="modal-change-password"
    title="Change Password"
    centered
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-alert
      variant="danger"
      :show="!!error"
    >
      {{ error }}
    </b-alert>
    <validation-observer
      ref="form"
      slim
    >
      <div class="position-relative">
        <validation-provider
          name="Old Password"
          rules="required|min:6|max:20"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Old Password"
            :invalid-feedback="errors[0]"
          >
            <b-form-input
              :type="typeOldPassword"
              v-model="oldPassword"
              placeholder="Enter your old password"
              :disabled="isBusy"
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
          <fa-icon
            class="icon-hide pointer"
            icon="eye"
            fixed-width
            @click="switchVisibilityOldPassword"
          />
        </validation-provider>
      </div>
      <div class="position-relative">
        <validation-provider
          name="New Password"
          :rules="`required|min:6|max:20|same:@${'Old Password'}`"
          v-slot="{ errors }"
        >
          <b-form-group
            label="New Password"
            :invalid-feedback="errors[0]"
          >
            <b-form-input
              :type="typeNewPassword"
              v-model="newPassword"
              placeholder="Enter your new password"
              :disabled="isBusy"
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
          <fa-icon
            class="icon-hide pointer"
            icon="eye"
            fixed-width
            @click="switchVisibilityNewPassword"
          />
        </validation-provider>
      </div>
      <div class="position-relative mb-5">
        <validation-provider
          name="Conrifm Password"
          :rules="`required|min:6|max:20|password:@${'New Password'}`"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Confirm Password"
            :invalid-feedback="errors[0]"
          >
            <b-form-input
              :type="typeConfirm"
              v-model="confirmPassword"
              placeholder="Confirm your password"
              :disabled="isBusy"
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
          <fa-icon
            class="icon-hide pointer"
            icon="eye"
            fixed-width
            @click="switchVisibilityConfirm"
          />
        </validation-provider>
      </div>
      <b-form-group class="pt-4">
        <b-button
          class="next shadow p-2 rounded"
          type="submit"
          variant="primary"
          block
          @click="submit"
          :disabled="!oldPassword || !newPassword || !confirmPassword || isBusy"
        >
          Save
          <b-spinner
            v-if="isBusy"
            class="ml-1"
            label="Spinning"
            small
          />
        </b-button>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  props: {},

  components: {},

  data: () => ({
    id: JSON.parse(localStorage.getItem('me')).id,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    isBusy: false,
    error: null,
    typeOldPassword: 'password',
    typeNewPassword: 'password',
    typeConfirm: 'password'
  }),

  computed: {},

  mounted() {},

  methods: {
    switchVisibilityOldPassword() {
      this.typeOldPassword = this.typeOldPassword === 'password' ? 'text' : 'password'
    },
    switchVisibilityNewPassword() {
      this.typeNewPassword = this.typeNewPassword === 'password' ? 'text' : 'password'
    },
    switchVisibilityConfirm() {
      this.typeConfirm = this.typeConfirm === 'password' ? 'text' : 'password'
    },
    async submit() {
      try {
        this.isBusy = true

        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        await api.user.changePassword(this.id, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        })
        this.error = this.$options.data().error
        this.oldPassword = this.$options.data().oldPassword
        this.newPassword = this.$options.data().newPassword
        this.confirmPassword = this.$options.data().confirmPassword
        this.typeOldPassword = this.$options.data().typeOldPassword
        this.typeNewPassword = this.$options.data().typeNewPassword
        this.typeConfirm = this.$options.data().typeConfirm

        this.$bvModal.hide('modal-change-password')
        this.$bvToast.toast('Success change password.', {
          headerClass: 'd-none',
          solid: true,
          variant: 'success'
        })
        this.isBusy = false
      } catch (response) {
        this.isBusy = false
        this.error = response.message.messageIdn
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password {
  background: url('') no-repeat scroll 7px 5px;
  padding-left: 25px;
  border: 1px solid darkgray;

  @media screen and (min-width: 992px) {
    background-position: 7px 5px;
  }
}

.icon-hide {
  color: var(--primary);
  position: absolute;
  right: 10px;
  top: 75%;
  transform: translateY(-65%);
}
</style>
