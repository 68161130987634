<template>
    <b-modal
        ref="modal-training-detail"
        id="modal-training-detail"
        centered
        hide-header
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        size="xl">
        <div class="container d-flex-lg-row d-flex; justify-content-start">
            <div class="mb-2 d-flex justify-content-between">
                <h5 class="font-weight-bold">Detail Training</h5>
                <b-link
                    class="text-muted"
                    @click="cancel"
                >
                    <fa-icon icon="times" size="lg" />
                </b-link>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <div>
                        <p class="mb-0 text-muted">Training Name</p>
                        <p class="font-weight-bold">{{ training.name }}</p>
                    </div>
                    <div>
                        <p class="mb-0 text-muted">Training Type</p>
                        <p class="font-weight-bold">{{ training.trainingType }}</p>
                    </div>
                    <div>
                        <p class="mb-0 text-muted">Provider</p>
                        <div v-for="(item, index) in training.provider" class="mb-2 font-weight-bold" :key="index">
                        {{ index+1}}. {{ item.name }}
                        </div>
                    </div>
                    <div>
                        <p class="mb-0 text-muted">Start Date</p>
                        <p class="font-weight-bold">{{ training.startDate ? moment(training.startDate).format('DD MMM YYYY') : "-"}}</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div>
                        <p class="mb-0 text-muted">End Date</p>
                        <p class="font-weight-bold">{{ training.endDate ? moment(training.endDate).format('DD MMM YYYY') : "-"}}</p>
                    </div>
                    <div>
                        <p class="mb-0 text-muted">Certificate Number</p>
                        <p class="font-weight-bold">{{ training.certificateNumber }}</p>
                    </div>
                    <div>
                        <p class="mb-0 text-muted">Certificate Expired</p>
                        <p class="font-weight-bold">{{training.certificateExpired ? moment(training.certificateExpired).format('DD MMM YYYY') : '-'}}</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="row">
                        <div class="col-6">
                            <p class="mb-0 text-muted">Training Context</p>
                            <div v-for="(item, index) in training.trainingContext" class="mb-2 font-weight-bold" :key="index">
                            {{ index+1}}. {{ item.name }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="mb-0 text-muted">Framework</p>
                        <div v-for="(item, index) in training.framework" class="mb-2 font-weight-bold" :key="index">
                        {{ index+1}}. {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div>
                        <p class="mb-0 text-muted">Operating System</p>
                        <div v-for="(item, index) in training.operatingSystem" class="mb-2 font-weight-bold" :key="index">
                        {{ index+1}}. {{ item.name }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <p class="mb-0 text-muted">Platform</p>
                            <div v-for="(item, index) in training.platform" class="mb-2 font-weight-bold" :key="index">
                            {{ index+1}}. {{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

export default {
  props: {
    training: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      providerName: [],
      trainingContext: [],
      framework: [],
      operatingSystem: [],
      platform: []
    }
  },

  methods: {
    cancel() {
      this.$bvModal.hide('modal-training-detail')
    }
  }
}
</script>
