  <template>
  <div>
    <b-row v-show="!training.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png"/>
          </div>
        </b-col>
    </b-row>
    <b-table
      ref="table"
      class="mt-2"
      v-show="training.length"
      striped
      :items="fetchListTraining"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
    >
      <template #cell(training-name)="{ training }">
        {{ training.name }}
      </template>
      <template #cell(startDate)="{ item }">
        {{item.startDate? moment(item.startDate).format('DD MMM YYYY') : '-'}}
      </template>
      <template #cell(endDate)="{ item }">
        {{item.endDate? moment(item.endDate).format('DD MMM YYYY') : '-'}}
      </template>
      <template #cell(certificate-number)="{ training }">
        {{ training.certificateNumber }}
      </template>
      <template #cell(certificateExpired)="{ item }">
        {{item.certificateExpired ? moment(item.certificateExpired).format('DD MMM YYYY') : '-'}}
      </template>
      <template #cell(trainingContext)="{ item }">
        {{ item.strTrainingContext }}
      </template>
      <template #cell(status-training)="{ training }">
        {{ training.statusCertificate }}
      </template>
      <template #cell(action)="{ item }">
        <div class="data; position-relative">
          <b-link
            class="text-secondary mx-2"
            @click="goToEditTraining(item.id)"
          >
            <fa-icon icon="edit" />
          </b-link>
          <b-link
            class="text-secondary mx-2"
            @click="viewTraining(item.id)"
          >
            <fa-icon icon="eye" />
          </b-link>
          <b-link
            class="text-secondary mx-2"
          >
            <fa-icon icon="trash-alt" @click="goToDeleteTraining(item.id)" />
          </b-link>
        </div>
      </template>
    </b-table>
    <modal-training-detail :training="singleTraining"/>
    <modal-confirm-delete-training
      @click="deleteTraining"
    />
    <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{
              `Show ${
                totalRows === 0 ? 0 : (currentPage - 1) * perPage + 1
              } to ${
                totalRows > currentPage * perPage
                  ? currentPage * perPage
                  : totalRows
              } of ${totalRows} entries`
            }}
          </span>
        </div>
      </div>
  </div>
</template>

<script>
import api from '@/api'
import modalTrainingDetail from '@/views/profileEmployee/trainingEmployee/ModalDetailTraining.vue'
import modalConfirmDeleteTraining from '@/views/profileEmployee/trainingEmployee/ModalConfirmDeleteTraining.vue'

export default {
  components: { modalTrainingDetail, modalConfirmDeleteTraining },
  name: 'TrainingEmployee',
  data: () => ({
    training: [
      {
        id: '',
        trainingName: '',
        startDate: '',
        endDate: '',
        certificateNumber: '',
        certificateExpired: '',
        trainingContext: '',
        statusCertificate: ''
      }
    ],
    singleTraining: {
      id: '',
      trainingName: '',
      startDate: '',
      endDate: '',
      certificateNumber: '',
      certificateExpired: '',
      trainingContext: [],
      statusCertificate: ''

    },
    candidateIdDelete: null,
    candidateIdView: null,
    candidateIdEdit: null,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      { key: 'name', label: 'Training Name' },
      { key: 'startDate', label: 'Start Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'endDate', label: 'End Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'certificateNumber', label: 'Certificate Number', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'certificateExpired', label: 'Certificate Expired', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'trainingContext', label: 'Training Context', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'statusCertificate', label: 'Status', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    pageOptions: [10, 20, 50, 100],
    items: []
  }),
  methods: {
    goToEditTraining(index) {
      this.candidateIdEdit = index
      this.$router.push({
        path: `/training/${index}`,
        query: {
          id: index.id
        }
      })
    },
    async editTraining(id) {
      const { data } = await api.training.get(id)
      this.singleTraining = data.data
      this.goToEditTraining(id)
    },
    goToDeleteTraining(index) {
      this.candidateIdDelete = index
      this.$bvModal.show('modal-confirm-delete-training')
    },
    async fetchInfo(id) {
      const data = await api.training.getById(id)
      this.training = data.data
    },
    async viewTraining(id) {
      const { data } = await api.training.getById(id)
      this.singleTraining = data.data
      this.goToViewTraining(id)
    },
    async fetchListTraining() {
      const { data } = await api.training.list(JSON.parse(localStorage.getItem('me')).employeeId, {
        Page: this.currentPage,
        PageSize: this.perPage
      })
      this.training = data.data.length !== 0 ? data.data : []
      this.totalRows = data.data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage

      let strTrainingContext = ''
      this.training.map((v, i) => {
        strTrainingContext = ''
        v.trainingContext.map((k, j) => {
          if (j === (v.trainingContext.length - 1)) strTrainingContext += k.name
          else strTrainingContext += `${k.name}, `
        })
        v.strTrainingContext = strTrainingContext

        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.training
    },
    goToViewTraining(index) {
      this.candidateIdView = index
      this.$bvModal.show('modal-training-detail')
    },
    async deleteTraining() {
      try {
        this.isBusy = true
        await api.training.erased(this.candidateIdDelete)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success delete Training', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.fetchListTraining()
        this.$router.push('/view-profile')
        this.$refs.table.refresh()
        setTimeout(() => {
          this.$bvModal.hide('modal-confirm-delete-training')
        }, 2000)
        this.$router.push('/view-profile')
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    }
  }
}
</script>
