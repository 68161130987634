<template>
    <div>
        <b-card ref="profile">
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-row position-relative">
                  <b-avatar :src="avatarImage" rounded size="72px" @change="$refs.profile.refresh()"></b-avatar>
                  <div class="position-relative" style="padding-left: 1rem;">
                    <h6 class="font-weight-bold text-nowrap">{{ employee.fullName }}</h6>
                    <h6 class="font-weight-bold">{{ employee.employeeNo }}</h6>
                    <h6 class="font-weight-bold">{{ employee.status }}</h6>
                  </div>
                </div>
                <div class="d-flex flex-column position-relative align-items-center">
                  <div>
                    <b-button
                      class="next merge mt-6 ml-50 mb-1"
                      variant="warning"
                      @click="changeProfile"
                    >
                      Change Profile
                    </b-button>
                  </div>
                  <div>
                    <b-button
                      class="next merge mt-8 ml-50 mb-1"
                      variant="warning"
                      @click="goToGenerateProfile"
                      style="padding-left: 58.5px; padding-right: 58.5px"
                    >
                      Print as PDF
                    </b-button>
                  </div>
                  <div>
                    <b-button
                      class="next merge mt-8 ml-50"
                      variant="warning"
                      @click="openModalChangePassword"
                      style="padding-left: 38.5px; padding-right: 38.5px"
                    >
                      Change Password
                    </b-button>
                  </div>
                </div>
            </div>
        </b-card>
        <br>
        <b-card>
            <b-tabs content-class="mt-3">
                <b-tab title="Profile" :active="activeTab === 'Profile'">
                    <h3>Employee's Profile</h3>
                    <DataEmployee />
                </b-tab>
                <b-tab title="Training" :active="activeTab === 'Training'">
                    <b-card>
                        <div class="row">
                          <div class="col-lg-6 d-flex justify-content-start align-items-center">
                            <h4>Employee's Training</h4>
                          </div>
                          <div class="col-lg-6 d-flex justify-content-end">
                            <b-button
                              class="next merge mt-6 ml-50"
                              variant="warning"
                              @click="addTraining"
                            >
                              Add Training
                            </b-button>
                          </div>
                        </div>
                        <br>
                        <TrainingEmployee/>
                    </b-card>
                    <br>
                </b-tab>
            </b-tabs>
        </b-card>
      <modal-change-password />
    </div>
</template>

<script>
import api from '@/api'
import DataEmployee from '@/components/DataEmployee.vue'
import TrainingEmployee from '@/components/TrainingEmployee.vue'
import ModalChangePassword from './ModalChangePassword.vue'

export default {
  data() {
    return {
      employeeId: JSON.parse(localStorage.getItem('me')).employeeId,
      activeTab: localStorage.getItem('activeTab') || 'Profile',
      id: '',
      employee: {},
      show: true,
      image: null,
      avatarImage: null
    }
  },
  components: { DataEmployee, TrainingEmployee, ModalChangePassword },
  async created() {
    this.fetchProfileEmployee()
    await this.fetchAvatarImage()
    if (!localStorage.getItem('activeTab')) {
      localStorage.setItem('activeTab', 'Profile')
      this.activeTab = 'Profile'
    }
  },
  methods: {
    async fetchProfileEmployee() {
      const { data } = await api.employee.getById(this.employeeId)
      this.employee = data.data
      localStorage.setItem('user_employee_login', JSON.stringify(this.employee))
    },
    async fetchAvatarImage() {
      const image = await api.avatar.getAvatar(this.employeeId)
      this.images = image.data.contentUrl
      this.avatarImage = 'https://internal-api.staging-gi.web.id' + this.images
    },
    changeProfile() {
      this.$router.push('/employee/edit-profile', { data: this.employee })
    },
    goToGenerateProfile() {
      this.$router.push('/generate-profile')
    },
    openModalChangePassword() {
      this.$bvModal.show('modal-change-password')
    },
    addTraining() {
      this.$router.push('/training/add-training-employee')
    }
  },
  destroyed() {
    localStorage.removeItem('activeTab')
  }
}
</script>
