<template>
    <div class="container d-flex-lg-row d-flex justify-content-start">
      <div class="row">
        <div class="col-lg-4">
          <h4>General Information</h4>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Fullname</p>
              <span class="font-weight-bold">{{ employee.fullName ? employee.fullName : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Nickname</p>
              <span class="font-weight-bold">{{ employee.nickName ? employee.nickName : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Identity Card</p>
              <span class="font-weight-bold">{{ employee.identityCard ? employee.identityCard : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >NPWP</p>
              <span class="font-weight-bold">{{ employee.npwp ? employee.npwp : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Gender</p>
              <span class="font-weight-bold">{{ employee.gender ? employee.gender : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Religion</p>
              <span class="font-weight-bold">{{ employee.religion ? employee.religion.name : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Birthdate</p>
              <span class="font-weight-bold">{{ employee.birthDate ? moment(employee.birthDate).format('DD MMM YYYY') : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Birthplace</p>
              <span class="font-weight-bold">{{ employee.birthPlace ? employee.birthPlace : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Age</p>
              <span class="font-weight-bold">{{ employee.age ? employee.age : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Vaccine Number</p>
              <span class="font-weight-bold">{{ employee.vaccineNumber ? employee.vaccineNumber : '-' }}</span>
          </div>

          <h4 class="mt-4">Address</h4>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Address</p>
              <span class="font-weight-bold">{{ employee.address ? employee.address : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Province</p>
              <span class="font-weight-bold">{{ employee.province ? employee.province.name : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >City</p>
              <span class="font-weight-bold">{{ employee.city ? employee.city.name : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >District</p>
              <span class="font-weight-bold">{{ employee.district ? employee.district.name : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Postal Code</p>
              <span class="font-weight-bold">{{ employee.postalCode ? employee.postalCode : '-' }}</span>
          </div>

        </div>
        <div class="col-lg-4">
          <h4>Employee Information</h4>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Employee Number</p>
              <span class="font-weight-bold">{{ employee.employeeNo ? employee.employeeNo : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Position</p>
              <span class="font-weight-bold">{{ employee.position ? employee.position : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Role</p>
              <span class="font-weight-bold">{{ employee.role ? employee.role : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Join Date</p>
              <span class="font-weight-bold">{{ employee.joinDate ? moment(employee.joinDate).format('DD MMM YYYY') : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Resign Date</p>
              <span class="font-weight-bold">{{ employee.resignDate ? moment(employee.resignDate).format('DD MMM YYYY') : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Year(s) Working</p>
              <span class="font-weight-bold">{{ employee.yearsWorking ? employee.yearsWorking : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Bank Name</p>
              <span class="font-weight-bold">{{ employee.bank ? employee.bank.name : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Bank Account Number</p>
              <span class="font-weight-bold">{{ employee.bankAccNumber ? employee.bankAccNumber : '-' }}</span>
          </div>

          <h4 class="mt-4">Contact</h4>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title" >Email</p>
              <span class="font-weight-bold">{{ employee.email ? employee.email : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Phone Number</p>
              <span class="font-weight-bold">{{ employee.phoneNumber ? employee.phoneNumber : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Emergency Contact Number</p>
              <span class="font-weight-bold">{{ employee.emergencyContactNumber || employee.emergencyContactName ? `${employee.emergencyContactNumber} (${employee.emergencyContactName})` : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Username Social Media</p>
              <div v-if="employee.socialMedia.length">
                  <div v-for="(item, index) in employee.socialMedia" :key="index">
                      <p class="mb-0 font-weight-bold">{{ index+1}}. {{ item.username ? item.username : '-' }}</p>
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Link Social Media</p>
              <div v-if="employee.socialMedia.length">
                  <div v-for="(item, index) in employee.socialMedia" :key="index">
                      <a @click="openLink(item.link)" class="mb-0 font-weight-bold">{{ index+1 }}. {{ item.link ? item.link : '-' }}</a>
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
        </div>
        <div class="col-lg-4">
          <h4>Knowledge</h4>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Objective</p>
              <span class="font-weight-bold">{{ employee.objective ?  employee.objective : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Profile</p>
              <span class="font-weight-bold">{{ employee.profile ? employee.profile : '-' }}</span>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Language</p>
              <div v-if="employee.language">
                  <div v-for="(item, index) in employee.language" class="font-weight-bold" :key="index">
                      {{ index+1}}. {{ item.name ? item.name : '-' }}
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Programming Language</p>
              <div v-if="employee.knowledge.programmingLanguage.length">
                  <div v-for="(item, index) in employee.knowledge.programmingLanguage" class="font-weight-bold" :key="index">
                      {{ index+1}}. {{ item.name ? item.name : '-' }}
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Framework</p>
              <div v-if="employee.knowledge.framework.length">
                  <div v-for="(item, index) in employee.knowledge.framework" class="font-weight-bold" :key="index">
                      {{ index+1}}. {{ item.name ? item.name : '-' }}
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
            <p class="mb-0 text-muted title">Database</p>
            <div v-if="employee.knowledge.database.length">
                <div v-for="(item, index) in employee.knowledge.database" class="font-weight-bold" :key="index">
                    {{ index+1}}. {{ item.name ? item.name : '-' }}
                </div>
            </div>
            <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Platform</p>
              <div v-if="employee.knowledge.platform.length">
                  <div v-for="(item, index) in employee.knowledge.platform" class="font-weight-bold" :key="index">
                      {{ index+1}}. {{ item.name ? item.name : '-' }}
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Development Tools</p>
              <div v-if="employee.knowledge.developmentTools.length">
                  <div v-for="(item, index) in employee.knowledge.developmentTools" class="font-weight-bold" :key="index">
                      {{ index+1}}. {{ item.name ? item.name : '-' }}
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Operating System</p>
              <div v-if="employee.knowledge.operatingSystem.length">
                  <div v-for="(item, index) in employee.knowledge.operatingSystem" class="font-weight-bold" :key="index">
                      {{ index+1}}. {{ item.name ? item.name : '-' }}
                  </div>
              </div>
              <div v-else class="font-weight-bold">-</div>
          </div>
          <div class="d-flex mb-1">
              <p class="mb-0 text-muted title">Others</p>
              <span class="font-weight-bold">{{ employee.other ? employee.other : '-' }}</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import api from '@/api'

export default {
  name: 'DataEmployee',
  data() {
    return {
      employee: {
        socialMedia: [],
        religion: [{
          name: ''
        }],
        bank: [{
          name: ''
        }],
        address: {},
        province: {},
        city: {},
        district: {},
        knowledge: {
          database: [],
          developmentTools: [],
          programmingLanguage: [],
          platform: [],
          framework: [],
          operatingSystem: []
        }
      }
    }
  },
  methods: {
    openLink(link) {
      window.open(`https://${link}`)
    },
    async fetchUserData() {
      const { data } = await api.employee.getById(JSON.parse(localStorage.getItem('me')).employeeId)
      this.employee = data.data
    }
  },
  created() {
    this.fetchUserData()
  }
}
</script>

<style scoped>
.title {
  width: 160px;
}
</style>
