<template>
  <b-modal
    ref="modal-confirm-delete-training"
    id="modal-confirm-delete-training"
    centered
    hide-footer
    hide-header
  >
    <div class="px-3 py-5 text-center">
      <img src="@/assets/close_up.png" alt="" width="120" height="120">
      <h4 class="text-bold mt-4">Delete Training</h4>
      <p class="text-muted mb-5">Are you sure want to delete this training ?</p>
      <div class="mt-3 d-flex justify-content-center">
      <b-button
      class="reset mr-3"
          variant="outline-warning"
      @click="onClickBack">
        No, Cancel
      </b-button>
      <b-button
        class="next"
        variant="warning"
        @click="remove">
        Delete
      </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  methods: {
    onClickBack() {
      this.$bvModal.hide('modal-confirm-delete-training')
    },
    remove() {
      this.$bvModal.hide('modal-confirm-delete-training')
      this.$emit('click')
    }
  }
}
</script>
